@font-face {
  font-family: 'Akkurat';
  src: url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Regular.woff2) format('woff2'),
       url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Akkurat';
  src: url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Bold.woff2) format('woff2'),
       url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Akkurat';
  src: url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-BoldItalic.woff2) format('woff2'),
       url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-BoldItalic.woff) format('woff');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Akkurat';
  src: url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Italic.woff2) format('woff2'),
       url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratLLWeb-Italic.woff) format('woff');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Akkurat Mono';
  src: url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratMonoLLWeb-Regular.woff2) format('woff2'),
       url(https://kit-artifacts.s3.eu-west-1.amazonaws.com/AkkuratMonoLLWeb-Regular.woff) format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  position: fixed;
  overflow: hidden;
}

* {
  -webkit-tap-highlight-color: transparent;
}

#container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --tp-base-background-color: hsla(0, 0%, 10%, 0.80);
  --tp-base-shadow-color: hsla(0, 0%, 0%, 0.2);
  --tp-button-background-color: hsla(0, 0%, 80%, 1.00);
  --tp-button-background-color-active: hsla(0, 0%, 100%, 1.00);
  --tp-button-background-color-focus: hsla(0, 0%, 95%, 1.00);
  --tp-button-background-color-hover: hsla(0, 0%, 85%, 1.00);
  --tp-button-foreground-color: hsla(0, 0%, 0%, 0.80);
  --tp-container-background-color: hsla(0, 0%, 0%, 0.30);
  --tp-container-background-color-active: hsla(0, 0%, 0%, 0.60);
  --tp-container-background-color-focus: hsla(0, 0%, 0%, 0.50);
  --tp-container-background-color-hover: hsla(0, 0%, 0%, 0.40);
  --tp-container-foreground-color: hsla(0, 0%, 100%, 0.50);
  --tp-groove-foreground-color: hsla(0, 0%, 0%, 0.20);
  --tp-input-background-color: hsla(0, 0%, 0%, 0.30);
  --tp-input-background-color-active: hsla(0, 0%, 0%, 0.60);
  --tp-input-background-color-focus: hsla(0, 0%, 0%, 0.50);
  --tp-input-background-color-hover: hsla(0, 0%, 0%, 0.40);
  --tp-input-foreground-color: hsla(0, 0%, 100%, 0.50);
  --tp-label-foreground-color: hsla(0, 0%, 100%, 0.50);
  --tp-monitor-background-color: hsla(0, 0%, 0%, 0.30);
  --tp-monitor-foreground-color: hsla(0, 0%, 100%, 0.30);
}